<template>
  <div class="m-shop-item">
    <NuxtLink
      :to="getUrl(`${shop?.code}`)"
      class="m-shop-item__link"
      no-prefetch
    >
      {{ shop.name }}
    </NuxtLink>

    <div class="m-shop-item__img">
      <AImage
        :src="getImageUrlWithSize(shop.imagesList[0]?.url ?? '', sizes)"
        :mobile-src="getImageUrlWithSize(shop.imagesList[0]?.url ?? '', sizes, true)"
        :alt="shop.imagesList[0]?.alt"
        with-retina
        with-webp
        width="336"
        height="336"
      />
      <div
        v-if="shop.isNew || shop.additionalDiscount"
        class="m-shop-item__badges"
      >
        <ServerRender
          is="Badge"
          v-if="shop.isNew"
          color="green"
          border="xs"
        >
          {{ $t("molecules.shop-item.new") }}
        </ServerRender>
        <ServerRender
          is="Badge"
          v-if="shop.additionalDiscount"
          border="xs"
        >
          -{{ shop.additionalDiscount }}%
        </ServerRender>
      </div>
      <div
        v-if="shop.rating"
        class="m-shop-item__rating"
      >
        <span class="i-star-white">{{ shop.rating }}</span>
      </div>
    </div>
    <div class="m-shop-item__info">
      <div v-if="shop.metro">
        <AMetro
          :color="shop.metro?.colorHex"
          logo
        />
        <span class="m-shop-item__place">{{ shop.metro.name }}</span>
      </div>
      <span v-else class="m-shop-item__place i-geo-line">{{ shop.name }}</span>
      <p v-if="shop.partnerName" class="hide">
        {{ shop.partnerName + $t('molecules.mapShops.partner') }}
      </p>
      <p>{{ shop.address }}</p>
      <p
        v-if="workingHours"
        ref="workingHoursRef"
      >
        {{ workingHours }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ServerRender from '@/components/helpers/ServerRender'

import {
  computed,
  defineComponent,
  watch,
  ref,
  onMounted
} from 'vue'
import dayjs from 'dayjs'
import { useLocalization } from '#imports'
import { useNavigate } from '@/composables/useNavigate'
import { getImageUrlWithSize } from '@/utils/url'

import type { PropType } from 'vue'
import type { Shop } from '@/modules/nuxt-api/models/Shop'

import AImage from '@/components/atoms/Image/AImage.vue'
import AMetro from '@/components/atoms/Metro/AMetro.vue'

defineComponent({ name: 'MShopItem' })

const props = defineProps({
  shop: {
    type: Object as PropType<Shop & { rating?: number, street?: string }>,
    required: true
  }
})

const { t } = useLocalization()
const workingHoursRef = ref<HTMLElement>()
const { getUrl } = useNavigate()

const isOpened = computed(() => {
  if (
    !props.shop.workingHours?.pb_default?.timeFrom &&
    !props.shop.workingHours?.pb_default?.timeTo
  ) { return false }
  const openingTime = props.shop.workingHours?.pb_default?.timeFrom
    .split(':')
    .map(el => +el)
  const closingTime = props.shop.workingHours?.pb_default?.timeTo
    .split(':')
    .map(el => +el)

  const opening = dayjs().hour(openingTime[0]).minute(openingTime[1])
  const closing = (
    closingTime[0] < openingTime[0] ? dayjs().add(1, 'day') : dayjs()
  )
    .hour(closingTime[0])
    .minute(closingTime[1])
  const now = dayjs()

  return now.diff(opening) > 0 && now.diff(closing) < 0
})

const workingHours = computed(() => {
  if (
    !props.shop.workingHours?.pb_default?.timeFrom &&
    !props.shop.workingHours?.pb_default?.timeTo
  ) { return '' }

  return isOpened.value
    ? `${t('molecules.shop-item.open-until')} ${
      props.shop.workingHours?.pb_default?.timeTo
    }`
    : `${t('molecules.shop-item.opens-at')} ${
      props.shop.workingHours?.pb_default?.timeFrom
    }`
})

const setOpenedClass = (value: boolean): void => {
  if (workingHoursRef.value !== undefined) {
    value
      ? workingHoursRef.value.classList.add('opened')
      : workingHoursRef.value?.classList.remove('opened')
  }
}
const sizes = {
  mobile: {
    w: '188',
    h: '260'
  },
  desktop: {
    w: '336',
    h: '336'
  }
}

watch(isOpened, setOpenedClass)

onMounted(() => {
  setOpenedClass(isOpened.value)
})
</script>

<style lang="postcss">
.m-shop-item {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 24rem;
  min-height: 29.25rem;
  cursor: pointer;

  &__img {
    position: relative;
    overflow: hidden;
    height: 21rem;

    & > .image {
      height: 100%;

      & > img {
        object-fit: cover;
        min-height: 100%;
        transition: transform 0.3s linear;
      }

      &::after {
        content: "";
        position: absolute;
        background-image: linear-gradient(
          180deg,
          var(--color-neutral-900) 0%,
          var(--color-neutral-900) 20%,
          rgb(42 36 37 / 0%) 50%
        );
        opacity: 0.4;
        inset: 0;
      }
    }

    &::after {
      content: "";
      position: absolute;
      background-color: var(--bg-shadow-base);
      opacity: 0;
      transition: opacity 0.3s linear;
      inset: 0;
    }
  }

  &__link {
    position: absolute;
    z-index: 1;
    opacity: 0;
    inset: 0;
  }

  &__place {
    display: flex;
    gap: var(--spacer-3xs);
    align-items: center;

    @mixin text-xl;
  }

  &__info {
    color: var(--color-text-dark);

    & > div {
      display: flex;
      gap: var(--spacer-3xs);
      align-items: center;
    }

    & > p:first-of-type {
      margin-bottom: var(--spacer-3xs);
      color: var(--color-text-middle);
    }

    & > p:last-of-type {
      color: var(--color-warning-dark);

      &.opened {
        color: var(--color-success-dark);
      }
    }
  }

  &__badges {
    position: absolute;
    top: var(--spacer-xs);
    left: var(--spacer-xs);
    display: flex;
    flex-direction: column;
    gap: var(--spacer-4xs);

    & > .badge {
      z-index: unset;
      width: fit-content;
    }
  }

  &__rating {
    position: absolute;
    top: 0;
    right: var(--spacer-xs);
    padding: var(--spacer-xs) var(--spacer-xs) var(--spacer-3xs);
    background-color: var(--color-warning-dark);
    color: var(--color-white);

    & > span {
      display: flex;
      flex-direction: column;
      gap: var(--spacer-3xs);
      align-items: center;

      &::before {
        width: 2rem;
        height: 2rem;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: calc(-1 * var(--spacer-3xs));
      left: 0;
      width: 100%;
      height: var(--spacer-xs);
      border-bottom-right-radius: var(--border-radius-2xs);
      border-bottom-left-radius: var(--border-radius-2xs);
      background-color: var(--color-warning-dark);
      transform: skewY(-10deg);
    }

    @mixin text-lg;
  }

  & .a-metro {
    flex-shrink: 0;
  }

  @media (--screen-lg) {
    border-top-left-radius: var(--border-radius-lg);
    border-top-right-radius: var(--border-radius-lg);
    isolation: isolate;

    &__info {
      padding-top: var(--spacer-base);
      background-color: var(--color-white);
    }

    @media (hover: hover) {
      &:hover {
        .m-shop-item__img {
          & > .image > img {
            transform: scale(1.05);
          }

          &::after {
            opacity: 1;
          }
        }
      }
    }
  }

  @media (--screen-xs) {
    width: 11.75rem;
    height: 16.25rem;
    min-height: auto;
    border-radius: var(--border-radius-base);

    & .a-metro {
      width: 16px;
      height: 16px;

      &.a-metro--color,
      &::before {
        width: 16px;
        height: 16px;
      }
    }

    &__img {
      height: 100%;

      &::after {
        top: 8.75rem;
        background: linear-gradient(0deg, #2d2d2d 50%, rgb(45 45 45 / 0%) 100%);
        opacity: 0.8;
      }
    }

    &__info {
      position: absolute;
      bottom: 0;
      padding: var(--spacer-2xs);
      color: var(--color-white);

      & > p:first-of-type {
        margin-bottom: 0;
        color: #fff
      }

      & > p:last-of-type {
        display: none;
      }

      .hide {
        display: none;
      }

      @mixin text-xs-semibold;
    }

    &__place {
      &::before {
        width: 1rem;
        height: 1rem;
        background-image: url("/assets/icons/general.svg#geo-line-white");
      }

      @mixin text-sm-semibold;
    }

    &__badges {
      top: var(--spacer-2xs);
      left: var(--spacer-2xs);

      & > .badge {
        --badge-spacer-vertical: 0;

        @mixin text-xs-bold;
      }
    }

    &__rating {
      right: var(--spacer-2xs);
      padding: var(--spacer-3xs) var(--spacer-3xs) var(--spacer-5xs);

      & > span {
        gap: var(--spacer-4xs);

        &::before {
          width: 1rem;
          height: 1rem;
        }
      }

      &::after {
        bottom: calc(-1 * var(--spacer-3xs));
        height: var(--spacer-2xs);
      }

      @mixin text-xs-semibold;
    }
  }
}
</style>
