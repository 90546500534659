<template>
  <div class="">
    <OCarouselOffer
      v-if="hasShops"
      :title="$t('pages.main.shops')"
      :break-points="{
      mobile: 6,
      laptop: 3,
      desktop: 4,
    }"
      slides-per-group
      class="main-page__shops"
    >
      <MInfoBanner
        :num="shopCount"
        :city="currentRegionName"
        is-button
        @open:shop="openShopModal"
      />

      <MShopItem
        v-for="shop in ratingShopListItem"
        :key="shop.id"
        :shop="shop"
      />
    </OCarouselOffer>
  </div>
</template>

<script lang="ts" setup>
import { defineComponent } from 'vue'
import { useConfig } from '@/stores/config'
import { useShopStore } from '@/stores/shop'

import OCarouselOffer from '@/components/organisms/CarouselOffer/OCarouselOffer.vue'
import MInfoBanner from '@/components/molecules/InfoBanner/MInfoBanner.vue'
import MShopItem from '@/components/molecules/ShopItem/MShopItem.vue'

defineComponent({ name: 'HomeAdvantages' })

const { currentRegionName } = useConfig()
const { shopListData, openShopModal, getShops } = useShopStore()
const shopCount = computed(() => shopListData.value?.length)

const { data: hasShops } = await useHydrationData('shops-has-any', async () => {
  const data = await getShops()

  return data.length > 0
})

const getRating = (id: number): number | undefined => {
  switch (id) {
    case 1:
      return 4.6
    case 2:
      return 4.5
    case 3:
      return 4.9
    case 4:
      return 4.6
    case 5:
      return 4.7
    case 7:
      return 4.6
    case 8:
      return 4.9
    case 9:
      return 4.5
    case 14:
      return 4.7
    case 15:
      return 4.6
    case 18:
      return 4.5
    case 27:
      return 4.5
    case 31:
      return 4.6
    case 34:
      return 4.6
    case 35:
      return 4.4
    case 36:
      return 4.6
    case 38:
      return 4.7
    case 39:
      return 4.6
    case 51:
      return 4.6
    case 52:
      return 4.5
    case 55:
      return 4.4
    case 64:
      return 4.7
    case 68:
      return 4.6
    case 70:
      return 4.5
    default:
      return undefined
  }
}

const ratingShopListItem = computed(() => {
  return shopListData.value?.map?.((item) => {
    return {
      ...item,
      rating: getRating(item.id)
    }
  })
})
</script>
